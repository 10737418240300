/* eslint no-console:0 */

// MODULES =====================================================================
import Rails from 'rails-ujs'

import "stylesheets/application.sass"
import "stylesheets/tailwind.sass"
import ImageObjectFit from "@utils/image-object-fit"
import Modale from "@utils/modale"
import ConstantHeight from "@utils/constant-height"
import FlashMessage from "@utils/flash-messages"
import AnimationScroll from "@utils/animation-scroll"
import executeRecaptcha from "@utils/grecaptcha"
import CarouselTab from "@utils/carousel-tab"

// Fonts ===============================================================
import "typeface-roboto"
import "typeface-oswald"

// Stimulus
import "@controllers/application"

// CONFIGURATION ===============================================================
Rails.start()

window.FlashMessage = FlashMessage
window.executeRecaptcha = executeRecaptcha
window.Rails = Rails

document.addEventListener("DOMContentLoaded", () => {
  new ImageObjectFit()
  new Modale()
  new ConstantHeight()
  new AnimationScroll()
  new CarouselTab()
})
