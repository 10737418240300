import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["button", "label", "content"]

  toggleActive (e) {
    this.resetActiveButton()
    e.target.classList.add('active')

    this.contentTargets.forEach((element) => {
      if (e.target.dataset.tabName === element.dataset.tabName) {
        element.classList.add('active')

        if (this.hasLabelTarget) {
          this.labelTarget.innerHTML = e.target.innerHTML
        }
      } else {
        element.classList.remove('active')
      }
    })
  }

  resetActiveButton () {
    this.buttonTargets.forEach((element) => {
      element.classList.remove('active')
    })
  }
}
