import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["item"]

  static values = {
    className: String
  }

  connect () {
    // Hang a reference to the Stimulus controller instance off the DOM element
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this
    this.currentViewportTop = 0
    this.currentViewportHeight = 0

    this.className = this.classNameValue || 'in'

    window.addEventListener('load', () => {
      this.scrollHandler()
    }, false)

    window.addEventListener('resize', () => {
      this.removeAnimationHandler()
      this.scrollHandler()
    })
  }

  // Find current distance from the top page from en div element
  getCurrentDistanceFromTopPage (elem) {
    let distance = 0

    if (elem.offsetParent) {
      do {
          distance += elem.offsetTop
          elem = elem.offsetParent
      } while (elem)
    }
    return distance >= 0 ? distance : 0
  }

  // Place active class on elements visible from the viewport
  scrollHandler () {
    this.currentViewportTop = window.scrollY
    this.currentViewportHeight = window.scrollY + window.innerHeight

    this.itemTargets.forEach((element) => {
      const topElement = this.getCurrentDistanceFromTopPage(element)

      if (topElement >= this.currentViewportTop && topElement < this.currentViewportHeight) {
        element.classList.add(this.className)
      }
    })
  }

  removeAnimationHandler () {
    this.itemTargets.forEach((element) => {
      element.classList.remove(this.className)
    })
  }
}
