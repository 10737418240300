import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import ScrollTo from "stimulus-scroll-to"
import Remote from "stimulus-remote-rails"

const application = Application.start()
const context = require.context('@controllers/application', true, /\.js$/)
application.load(definitionsFromContext(context))

application.register("scroll-to", ScrollTo)
application.register("remote", Remote)
