import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container', 'filter']

  static classes = ['active']

  applyFilter (event) {
    event.preventDefault()

    // Exit function if the selected filter is the active filter
    if (event.target.classList.contains(this.activeClass)) return

    // Add specific CSS class to the selected filter and remove it from the other filters
    this.filterTargets.forEach(filter => filter.classList.toggle(this.activeClass, filter === event.target))

    const category = event.target.dataset.category

    // Hide the images that do not belong to the selected category
    // Toggle the 'item' class to display or not images in the lightGallery diaporama
    this.containerTargets.forEach(container => {
      const imageLink = container.querySelector("[data-gallery-filter-target='image']")

      if (!category || container.dataset.category === category) {
        container.style.display = ''
        imageLink.classList.add('item')
      } else {
        container.style.display = 'none'
        imageLink.classList.remove('item')
      }
    })

    // Update Macy and LightBox to show only the desired images
    this.toogleAnimationEffects(false)
    this.updateMacy()
    this.updateLightbox()
    this.toogleAnimationEffects(true)
  }

  updateMacy () {
    // Get the macy stimulus controller from its DOM element
    const macyController = this.element.querySelector("[data-controller='macy']").macy

    // Regenerate the images' grid through the macy instance of the macy stimulus controller
    macyController.macy.recalculate(true)
  }

  updateLightbox () {
    // Get the lightbox stimulus controller from its DOM element
    const lightboxController = this.element.querySelector("[data-controller='lightbox']").lightbox

    lightboxController.reconnect()
  }

  toogleAnimationEffects (enable) {
    if (!this.animationLoadingEffectController) {
      this.animationLoadingEffectController = this.element.querySelector("[data-controller='animation-loading-effect']")['animation-loading-effect']
    }

    if (enable) {
      this.animationLoadingEffectController.scrollHandler()
    } else {
      this.animationLoadingEffectController.removeAnimationHandler()
    }
  }
}
