import Swiper from 'swiper/bundle'

export default class {
  constructor () {
    this.$sliders = document.querySelectorAll('[data-carousel-tab]')

    if (this.$sliders) {
      this.bindEvents()
    }
  }

  bindEvents () {
    this.$sliders.forEach(element => { this.createSwiperInstance(element) })
  }

  createSwiperInstance (element) {
    new Swiper(element, {
      slidesPerView: 1,
      freeMode: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + this.slides[index].dataset.tab + '</span>'
        }
      }
    })
  }
}
