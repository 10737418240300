import Lightbox from "stimulus-lightbox"

export default class extends Lightbox {
  connect () {
    // Hang a reference to the Stimulus controller instance off the DOM element
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this
    super.connect()
  }

  reconnect () {
    const lightGallery = this.lightGallery
    // When destroying a lightGallery instance, the window scrolls to the top of the page. The line below prevents that
    // See https://github.com/sachinchoolur/lightgallery.js/issues/102
    lightGallery.prevScrollTop = window.pageYOffset
    lightGallery.destroy(true)
    this.connect()
  }

  get defaultOptions () {
    return {
      thumbnail: false,
      animateThumb: false,
      showThumbByDefault: false,
      download: false,
      selector: '.item'
    }
  }
}
